import axios from "axios";
import { useContext, useEffect, useState} from "react";

// STYLES
import '../../assets/styles/css/components/modal/modal.css';
import '../../assets/styles/css/components/modal/modalFormTeam.css';

// TYPES
import { UserContextType } from "../../models/types/contextTypes/userContextType";

// CONTEXT
import { UserContext } from "../../contexts/UserContext";

// HELPERS
import { validateName } from '../../helpers/ValidationHelper';

// MODELS
import IFormTeamModalProps from "../../models/modalProps/formTeamModalProps";
import ICreateTeamResponse from "../../models/interfaces/createTeamResponse";

// COMPONENTS
import LoadingSpinner from "../LoadingSpinner";

// CONFIGS
import Configs from "../../api/configs/configs";


const ModalTeamForm = ({hideModalService: hideModal, team: passedTeam, getMyTeamsService: getMyTeams} : IFormTeamModalProps) => {
    
    // CONTEXT
    const userContext = useContext(UserContext) as UserContextType;

    // STATES
    const [loading, setLoading] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [team_name, setTeamName] = useState<string>("");
    const [team_players, setTeamPlayers] = useState<string>("");
    const [team_logourl, setTeamLogoUrl] = useState<string>("");



    // ON START 
    useEffect(()=>{
        setTeamName(passedTeam.name);
        setTeamLogoUrl(passedTeam.logo_url);
        setTeamPlayers(passedTeam.members);
    }, []);


    
    // FUNCTIONS CORE ------------------------------
    function performFormTeam(){
        setMessage("");
        setLoading(true);

        if(!validateName(team_name)){
            setMessage("Invalid team name");
            setLoading(false);
            return;
        }
        if(!validateName(team_players)){
            setMessage("Invalid team players");
            setLoading(false);
            return;
        }

        let team_id = '';
        let endpoint_url = '';

        if(passedTeam.teamID === ''){
            team_id = team_name.replaceAll(' ', '').replaceAll('*', '').replaceAll('&', '').replaceAll('?', '');
            endpoint_url = Configs.GTEAMSTATS_BASEURL + 'TeamCreate.php';
        } else {
            team_id = passedTeam.teamID;
            endpoint_url = Configs.GTEAMSTATS_BASEURL + 'TeamUpdate.php';
        }
        
        
        const final_logourl = team_logourl.length === 0? 'https://elesteam.com/public_images/pteamstats_default_team_image.png' : team_logourl;

        let config = {
            headers: {
              'Accept': "application/json",
            },
            withCredentials: false
          }


        let body = {
            email: userContext.user.email,
            id: team_id,
            name: team_name,
            players: team_players,
            logourl: final_logourl,
            theme: 'orange'
        }
        console.log("a:" + body);

        axios.post<ICreateTeamResponse>(endpoint_url, body, config)
            .then(res => {
                setLoading(false);

                console.log(res);
                if(res.data.code === 1){
                    //CREATED/EDITED
                    hideModal();
                    getMyTeams();
                    
                } else {
                    setMessage(res.data.message)
                }
            })
            .catch(err => {
                setLoading(false);
                console.log("ERROR");
                console.log(err);
            });  
    }



    // VIEW RETURNED
    return (
        <div id="myModal" className="modal">
            <div className="modal-content">
                <span className="close" onClick={() => { hideModal();}}>&times;</span>
                <h1 className="modal-content-title"> {passedTeam.teamID === ''? 'NEW TEAM' : 'EDIT TEAM'}</h1>
                
                <label htmlFor="add_team_name" className="form-label">Team name</label>
                <input className="form-input" type="text" placeholder="Team name" id="add_team_name" onChange={e => {setTeamName(e.target.value)}} value={team_name}/>
                <label htmlFor="add_team_name" className="form-label">Team Players</label>
                <input className="form-input" type="text" placeholder="Comma separated players names ex. Elezero,Rocku" id="add_team_players"  onChange={e => {setTeamPlayers(e.target.value)}} value={team_players}/>
                <label htmlFor="add_team_logourl" className="form-label">Team logo image url</label>
                <input className="form-input" type="text" placeholder="ex. https://images.com/logo.png" id="add_team_logourl" onChange={e => {setTeamLogoUrl(e.target.value)}} value={team_logourl} />
                <button className="modal-button" onClick={() => {performFormTeam();}} > {passedTeam.teamID === ''? 'CREATE' : 'SAVE CHANGES'}</button>
                <button className="modal-button-secondary" onClick={() => {hideModal();}} >CANCEL</button>

                <b className="message_b_error">{message}</b>

                { loading  ?
                    <div className="div_spinner_container">
                    <LoadingSpinner/>
                    </div>
                : <></>}
            </div>
        </div>
    )
}

export default ModalTeamForm;