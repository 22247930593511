
// STYLES
import '../../assets/styles/css/components/modal/modal.css';
import '../../assets/styles/css/components/modal/modalHelp.css';

// IMAGES
import formula_img from '../../assets/images/formula.png';

// MODELS
import IHelpModalProps from "../../models/modalProps/helpModalProps";



const ModalHelp = ({hideModalService: hideHelpModal} : IHelpModalProps) => {

    return (
        <div id="myModal" className="modal">
            <div className="modal-content">
                <span className="close" onClick={() => { hideHelpModal();}}>&times;</span>
                <h1 className="modal-content-title">PUBG TEAM STATS</h1>
                <h1 className="modal-content-subtitle">Information</h1>
                <p className="modal-content-text">This tool uses official PUBG Developers API to retrieve players and matches information.</p>
                <h1 className="modal-content-subtitle">Matches information</h1>
                <p className="modal-content-text">The API does NOT serves some of old matches, therefore this tool can NOT calculate and show information about those matches</p>
                <h1 className="modal-content-subtitle">Rank and Score</h1>
                <p className="modal-content-text">Score is calculated by the following formula: </p>
                <img src={formula_img} className="modal-content-img" />
                <p className="modal-content-text-unmargered">Where:</p>
                <p className="modal-content-text-unmargered">chicken = Matches WON</p>
                <p className="modal-content-text-unmargered">kills = Players Killed in match</p>
                <p className="modal-content-text-unmargered">Knocked = Players Knocked in match</p>
                <p className="modal-content-text-unmargered">Assists = Assists to Kill a player in match</p>
                <p className="modal-content-text-unmargered">DamageDealt = Damage Dealt in match</p>
                <p className="modal-content-text-unmargered">TotalMatches = Total Matches</p>
                <p className="modal-content-text-unmargered highlighted-text">NOTE: The suffix <i>i</i> means each match</p>

                <h1 className="modal-content-subtitle">Disclaimer</h1>
                <p className="modal-content-text">This tool does NOT save any player information, only team players nicknames, team's name, theme selected and logo url. </p>
            </div>
        </div>
    )
}

export default ModalHelp;