import axios from "axios";
import { useState} from "react";

// STYLES
import '../../assets/styles/css/components/modal/modal.css';
import '../../assets/styles/css/components/modal/modalFormTeam.css';

// MODELS
import IFormTeamModalProps from "../../models/modalProps/formTeamModalProps";
import ICreateTeamResponse from "../../models/interfaces/createTeamResponse";

// COMPONENTS
import LoadingSpinner from "../LoadingSpinner";

// CONFIGS
import Configs from "../../api/configs/configs";


const ModalTeamDelete = ({hideModalService: hideModal, team: passedTeam, getMyTeamsService: getMyTeams} : IFormTeamModalProps) => {

    // STATES
    const [loading, setLoading] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    
    function performDeleteTeam(){
        setMessage("");
        setLoading(true);

        
        let config = {
            headers: {
              'Accept': "application/json",
            },
            withCredentials: false
          }
        let body = {
            team_id: passedTeam.teamID,
        }

        axios.post<ICreateTeamResponse>(Configs.GTEAMSTATS_BASEURL + 'TeamDelete.php', body, config)
            .then(res => {
                setLoading(false);

                console.log(res);
                if(res.data.code === 1){
                    //DELETED
                    hideModal();
                    getMyTeams();
                    
                } else {
                    setMessage(res.data.message)
                }
            })
            .catch(err => {
                setLoading(false);
                console.log("ERROR");
                console.log(err);
            });  
    }

    return (
        <div id="myModal" className="modal">
            <div className="modal-content">
                <span className="close" onClick={() => { hideModal();}}>&times;</span>
                <h1 className="modal-content-subtitle"> Are you sure want to delete team?</h1>
                
                <button className="modal-button" onClick={() => {performDeleteTeam();}} >DELETE</button>
                <button className="modal-button-secondary" onClick={() => {hideModal();}} >CANCEL</button>

                <b className="message_b_error">{message}</b>

                { loading  ?
                    <div className="div_spinner_container">
                    <LoadingSpinner/>
                    </div>
                : <></>}
            </div>
        </div>
    )
}

export default ModalTeamDelete;