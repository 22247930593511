import axios from "axios";
import {FC, useContext, useState} from "react";
import { UserContext } from "../contexts/UserContext";
import { ReactSession } from 'react-client-session';
import { useNavigate } from "react-router-dom";


// STYLES
import '../assets/styles/css/views/login.css';

// MODELS
import User from "../models/User";
import ILogin from "../models/interfaces/login";

// CONTEXT
import { UserContextType } from "../models/types/contextTypes/userContextType";


// COMPONENTS
import LoadingSpinner from "../components/LoadingSpinner";

// HELPERS
import { validateCreateAccount, validateLogin } from "../helpers/ValidationHelper";

// CONFIGS
import Configs from "../api/configs/configs";


const Login: FC = () => {

    // NAVIGATOR
    const navigate = useNavigate();
    
    // CONTEXT 
    const userContext = useContext(UserContext) as UserContextType;

    // STATES
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [username, setUsername] = useState<string>("");
    const [lgshow, setLgshow] = useState<number>(1); // 1: login, 2: signup
    const [loading, setLoading] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");


    // FUNCTIONS CORE -----------------------------------------------
    function toogleToLogin(){
        setLgshow(1);        
        setMessage("");
        setLoading(false);    
    }
    function toogleToSignup(){
        setLgshow(2);    
        setMessage("");
        setLoading(false);    
    }



    function performLogin() {   
        setLoading(true);
        setMessage("");

        if(!validateLogin(email)){
            setMessage("Invalid email");
            setLoading(false);
            return;
        }

        let config = {
            headers: {
              'Accept': "application/json",
            },
            withCredentials: false
          }

        let body = {
            email: email,
            password: password
        }

        axios.post<ILogin>(Configs.GTEAMSTATS_BASEURL + 'UserLogin.php', body, config)
            .then(res => {
                
                setLoading(false);
                
                if(res.data.code === 1){
                    //LOGGED
                    userContext.setUser(new User(email, res.data.data.username));
                    ReactSession.set("user", new User(email, res.data.data.username));
                    navigate("/myteams");
                } else {
                    setMessage(res.data.message);
                }
            })
            .catch(err => {
                setLoading(false);
                console.log("ERROR");
                console.log(err);
                setMessage("Internal error");
            });      
    }

    // CREATE ACCOUNT
    function performCreateAccount() {   
        setMessage("");
        setLoading(true);

        if(!validateCreateAccount(email, username, password)){
            setMessage("Invalid email, username or password");
            setLoading(false);
            return;
        }

        let config = {
            headers: {
              'Accept': "application/json",
            },
            withCredentials: false
          }

        let body = {
            email: email,
            username: username,
            password: password
        }

        axios.post<ILogin>(Configs.GTEAMSTATS_BASEURL + 'UserCreate.php', body, config)
            .then(res => {
                setLoading(false);

                if(res.data.code === 1){
                    //ACCOUNT CREATED
                    userContext.setUser(new User(email, username));
                    ReactSession.set("user", new User(email, username));
                    navigate("/myteams");
                } else {
                    setMessage(res.data.message)
                }
            })
            .catch(err => {
                setLoading(false);
                console.log("ERROR");
                console.log(err);
            });      
    }


    return (
        <div className="login_container">
            <div className="login-signup_div">
                <button className={ lgshow === 1? 'tab-button tab-button-active' : 'tab-button'} onClick={() => { toogleToLogin(); }}>Login</button>
                <button className={ lgshow === 2? 'tab-button tab-button-active' : 'tab-button'} onClick={() => { toogleToSignup(); }}>Create Account</button>
            </div>

            {lgshow === 1 ?
                <div className="login-div">
                    <h3 className="form-title">Login</h3>
                    <input type="email" readOnly={loading} value={email} placeholder="Email" className="form-input" onChange={(val) => {setEmail(val.target.value);}}/>
                    <input type="password" readOnly={loading} value={password} placeholder="Password" className="form-input" onChange={(val) => {setPassword(val.target.value);}}/>
                    <button className="btn-submit" onClick={() => {performLogin();}}>LOGIN</button>
                </div>
                : 
                <div className="signup-div">
                    <h3 className="form-title">Create Account</h3>
                    <input type="email" readOnly={loading} value={email} placeholder="Email" className="form-input" onChange={(val) => {setEmail(val.target.value);}}/>
                    <input type="username" readOnly={loading} value={username} placeholder="Username" className="form-input" onChange={(val) => {setUsername(val.target.value);}}/>
                    <input type="password" readOnly={loading} value={password} placeholder="Password" className="form-input" onChange={(val) => {setPassword(val.target.value);}}/>
                    <button className="btn-submit" onClick={() => {performCreateAccount();}}>CREATE ACCOUNT</button>
                </div>
            }
            <b className="message_b_error">{message}</b>

            { loading  ?
                <div className="div_spinner_container">
                  <LoadingSpinner/>
                </div>
            : <></>}
        </div>
    )
}

export default Login;