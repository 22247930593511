export default class PlayerStats {
    totalMatches: number = 0;

    totalKnocked: number = 0;
    knockedAverage: number = 0;            //   = totalKnocked / totalMatches

    totalAssists: number = 0;
    assistsAverage: number = 0;            //   = totalAssits / totalMatches

    totalDamageDealt: number = 0;
    damageDealtAverage: number = 0;        //   = totalDamageDealt / totalMatches

    totalHeadshotsKills: number = 0;
    headshotsKillsAverage: number = 0;     //   = totalHeadshots / totalMatches

    totalHeals: number = 0; // number of heal items used
    healAverage: number = 0;               //   = totalHeals / totalMatches

    killStreak: number[] = [];
    bestKillStreak: number = 0;                //   = max(killStreak)
    killStreakAverage: number = 0;         //   = totalKillStreak / totalMatches

    kills: number[] = [];
    totalKills: number = 0;        
    bestKills: number = 0;                 //  max(kills)     
    killsAverage: number = 0;              //   = totalKills / totalMatches

    longestKills: number[] = [];
    bestLongestKill: number = 0;           // max(longestKills)

    totalRevives: number = 0;   
    revivesAverage: number = 0;            //   = totalRevives / totalMatches

    totalRideDistance: number = 0;
    rideDistancesAverage: number = 0;      //   = totalRideDistance / totalMatches

    totalTeamKills: number = 0;
    teamKillsAverage: number = 0;          //   = totalTeamKills / totalMatches
    
    timesSurvived: number[] = [];
    totalTimeSurvived: number = 0;
    bestTimeSurvived: number = 0;         //   = max(timesSurvived)
    timeSurvivedAverage: number = 0;       //   = totalTimeSurvived / totalMatches

    totalVehicleDestroyed: number = 0;
    vehicleDestroyedAverage: number = 0;   //   = totalVehicleDestroyed / totalMatches

    totalWalkedDistance: number = 0;         
    walkedDistancesAverage: number = 0;    //   = totalWalkedDistances / totalMatches

    kdRate: number = 0;                    //   = totalKills / (totalMatches - totalChickenWon)

    winPlaces: number[] = [];
    totalWinPlace: number = 0;
    winPlaceAverage: number = 0;           //   = totalWinPlace / totalMatches
    bestWinPlace: number = 0;              //   = min(winPlaces)
    totalChickenWon: number = 0;           //   = count(winPlaces == 1)
    chickenWonAverage: number = 0;         //   = totalChickenWon / totalMatches

    name: string;
    playerId: string;

    playerScore: number = 0;

    constructor(
        name: string,
        playerId: string
        ){
            this.name = name;
            this.playerId = playerId;
    }
}