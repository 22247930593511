import {FC, useEffect, useState} from "react";
import axios from 'axios';
import { useParams } from "react-router-dom";

// HELPERS
import { isToday, isFromYesterday, isThisWeek, isLastWeek, isThisMonth, isLastMonth, isThisYear, isLastYear } from "../helpers/TimeHelper";
import { formatTnumber } from "../helpers/NumberHelper";

// MODELS
import IPlayerResponse from "../models/interfaces/playerResponse";
import PlayerStats from "../models/PlayerStats";
import IMatchDetails from "../models/interfaces/matchDetails";
import ITeamResponse from "../models/interfaces/teamsResponse";

// STYLES
import '../assets/styles/css/general/table.css';
import '../assets/styles/css/general/template.css';
import '../assets/styles/css/general/header.css';
import '../assets/styles/css/general/dropdown.css';
import '../assets/styles/css/views/playerStats.css';

// IMAGES
import medal_chicken from "../assets/images/medals/medal_chicken.png";
import medal_doctor from "../assets/images/medals/medal_doctor.png";
import medal_fitness from "../assets/images/medals/medal_fitness.png";
import medal_killer from "../assets/images/medals/medal_killer.png";
import medal_sniper from "../assets/images/medals/medal_sniper.png";
import medal_vampire from "../assets/images/medals/medal_vampire.png";

// CONFIGS
import Configs from '../api/configs/configs';

// COMPONENTS
import LoadingSpinner from "../components/LoadingSpinner";


// TYPES
import { teamStatsStateType } from "../models/types/viewStateTypes/teamStatsStateType";







const TeamStats: FC = () => {

  // PARAMS
  const { team_id } = useParams();

  // STATES
  const [timefilter, setTimefilter] = useState<string>("today");
  const [timeNamefilter, setTimeNamefilter] = useState<string>("TODAY");
  const [state, setState] = useState<teamStatsStateType>({
    loading: true,
    players: [],
    teamDetails: {teamID: '', theme: '', logo_url: '', members: '', name: ""},
    playersIds: [],
    playersStats: {},
    RankedPlayers: [],
    commonMatches: [],
    matchesDetails: [],
    matchesDetailsFiltered: [],
    step: 0, 
    matchGottenIndex: -1,
    medals: {
      chicken_owner: "-",
      chicken_val: 0,
      killer_owner: "-",
      killer_val: 0,
      sniper_owner: "-",
      sniper_val: 0,
      vampire_owner: "-",
      vampire_val: 0,
      doctor_owner: "-",
      doctor_val: 0,
      fitness_owner: "-",
      fitness_val: 0,
      knocked_owner: "-",
      knocked_val: 0,
      assists_owner: "-",
      assists_val: 0,
      kdrate_owner: "-",
      kdrate_val: 0
    }
  });


 

  
  // ON PAGE START
  useEffect(() => {
  }, []);

  // ON TIME FILTER CHANGE
  useEffect(() => {
    setState({...state, step: 5})
  }, [timefilter])

  // ON STATUS CHANGE
  useEffect(() => {    
    switch(state.step){
      case 0:
        // get Team Details getTeamDetails(); (executed at the begining)
        getTeamDetails();
        break;
      case 1: 
        // get Players Details
        getPlayersDetails();
        break;
      case 2: 
        // createPlayersStatsHashmap
        createPlayersStatsHashmap();
        break;
      case 3:
        // createCommonMatchesArray
        createCommonMatchesArray();
        break;
      case 4:
        // getMatches
        getMatches();
        break;
      case 5:
        // filter matches by time filter selected
        filterMatchesByTime();
        break;
      case 6: 
        // assign players stats
        assignPlayersStats();
        break;
      case 7: 
        // calculate player total and average stats
        calculatePlayersStats();
        break;
      case 8:
        // calculate players rank
        calculatePlayersRank();
        break;
      case 9:
        // calculate players rank
        calculateMedalsOwner();
        break;
      case 45:
        // FINISH
        //console.log("STATE: ");
        //console.log(state);
        console.log(state);
        console.log("FINISHED");
        break;

      default:
        break;
    }
  }, [state])



  // STEP 0: Get Team Info
  function getTeamDetails(){
    let config = {
        headers: {
          'Accept': "application/json",
        },
        withCredentials: false
      }

    let body = {
      team_id: team_id
    }

    axios.post<ITeamResponse>(Configs.GTEAMSTATS_BASEURL + 'TeamGet.php', body, config)
        .then(res => {
            
            if(res.data.code === 1){

                if(res.data.data.length>0){
                  let _teamDetails = {
                    teamID: res.data.data[0].team_id,
                    theme: res.data.data[0].team_theme,
                    logo_url: res.data.data[0].team_logourl,
                    members: res.data.data[0].team_players,
                    name: res.data.data[0].team_name,
                  }

                  
                  // SETTING THEME
                  getTheme(_teamDetails.theme);
                  
                  
                  setState({...state, teamDetails: _teamDetails, step: 1});
                }
            } else {
                //setMessage("TEAM NOT FOUND");
            }
        })
        .catch(err => {
            console.log("ERROR");
            console.log(err);
            //setMessage("TEAM NOT FOUND");
        }); 
  }

  // STEP 1: Get Players Details
  function getPlayersDetails() {
    let config = {
      headers: {
        'Authorization': 'Bearer ' + Configs.PUBG_TOKEN,
        'Accept': "application/vnd.api+json",
      }
    }
                          
    axios.get<IPlayerResponse>(`https://api.pubg.com/shards/steam/players?filter[playerNames]=`+state.teamDetails.members, config)
      .then(res => {
          setState({...state, players: res.data.data, step: 2});
      })
  }

  // STEP 2: Create Players Stats Hashmap
  function createPlayersStatsHashmap(){
    let _playersIds: string[] = [];

    state.players.map((_player, _index) => {
      _playersIds.push(_player.id);
    });

    setState({...state,  playersIds: _playersIds, step: 3})
  }

  // STEP 3: Create Common Matches Array
  function createCommonMatchesArray(){
    let _commonMatches: string[] = [];

    if(state.players.length > 1){
      let index_A = 0;
      let index_B = 1;

      while(index_A < state.players.length -1){
        index_B = index_A + 1;

        while(index_B < state.players.length){
          state.players[index_A].relationships.matches.data.map((_match_A, _index_match_A) => {
            state.players[index_B].relationships.matches.data.map((_match_B, _index_match_B) => {
              if(_match_A.id === _match_B.id){
                if(!_commonMatches.includes(_match_A.id)){
                  _commonMatches.push(_match_A.id);
                }
              }
            });
          });
          
          ++index_B;
        }
        ++index_A;
      }
      
    } else {
      // IF ONLY ONE PLAYER, SAVE ALL MATCHES
      state.players.map((_player, _playerIndex) => {
        _player.relationships.matches.data.map((_match, _matchIndex) => {
          _commonMatches.push(_match.id);
        })
      });
    }
    
    setState({...state, commonMatches: _commonMatches, step: 4})
  }

  // STEP 4 (ITERATABLE) : Get Matches
  function getMatches(){
    if(state.matchGottenIndex < state.commonMatches.length -1){
      getMatchDetails(state.commonMatches[state.matchGottenIndex+1], state.matchGottenIndex+1);
    } else {
      setState({...state, step: 5});
    }
  }

  // STEP 4.1: Get Match Details
  function getMatchDetails(matchId: string, gettingIndex: number){
    let config = {
      headers: {
        'Authorization': 'Bearer ' + Configs.PUBG_TOKEN,
        'Accept': "application/vnd.api+json",
      }
    }

    axios.get<IMatchDetails>(`https://api.pubg.com/shards/steam/matches/${matchId}`, config)
      .then(res => {
          setState({...state, matchesDetails: [...state.matchesDetails, res.data], step: 4, matchGottenIndex: gettingIndex});
      }).catch((err) => {
        console.log("ERROR GETTING MATCH ID: "+matchId);
        console.log(err);
        setState({...state, step: 4, matchGottenIndex: gettingIndex});
      })
  }

  // STEP 5: Filter Matches By Time
  function filterMatchesByTime(){
    let _matchDetailsFiltered: IMatchDetails[] = [];

    console.log(state.matchesDetails);
    console.log("matchesDetails");

    state.matchesDetails.map((_matchDetails, _md_index) => {
      if(_matchDetails.data.attributes.matchType === "official" || _matchDetails.data.attributes.matchType === "seasonal"){
        switch(timefilter){
          case "today":
            if(isToday(_matchDetails.data.attributes.createdAt))
                _matchDetailsFiltered.push(_matchDetails);
            break;
    
          case "yesterday":
              if(isFromYesterday(_matchDetails.data.attributes.createdAt))
                _matchDetailsFiltered.push(_matchDetails);
            break;
    
          case "this_week":
              if(isThisWeek(_matchDetails.data.attributes.createdAt) )
                _matchDetailsFiltered.push(_matchDetails);
            break;
    
          case "last_week":
              if(isLastWeek(_matchDetails.data.attributes.createdAt))
                _matchDetailsFiltered.push(_matchDetails);
            break;
    
          case "this_month":
              if(isThisMonth(_matchDetails.data.attributes.createdAt))
                _matchDetailsFiltered.push(_matchDetails);
            break;
    
          case "last_month":
              if(isLastMonth(_matchDetails.data.attributes.createdAt))
                _matchDetailsFiltered.push(_matchDetails);
            break;
    
          case "this_year":
              if(isThisYear(_matchDetails.data.attributes.createdAt))
                _matchDetailsFiltered.push(_matchDetails);
            break;
    
          case "last_year":
              if(isLastYear(_matchDetails.data.attributes.createdAt))
                _matchDetailsFiltered.push(_matchDetails);
            break;
    
          case "lifetime":
                _matchDetailsFiltered.push(_matchDetails);
            break;
    
          default:
        }
      }
    });

    setState({...state, matchesDetailsFiltered: _matchDetailsFiltered, step: 6});
  }

  // STEP 6: Assign Players Stats
  function assignPlayersStats(){

    let _playersStats: Record<string, PlayerStats> = {};

    state.players.map((_player, _index) => {
      let _playerStats = new PlayerStats(_player.attributes.name, _player.id);

      _playersStats[_player.id] = _playerStats;
    });


    state.matchesDetailsFiltered.map((_matchDetails, _md_index) => {
      _matchDetails.included.map((_participant, _participant_index) => {

        if(_participant !== undefined){
          if(_participant.attributes !== undefined){
            if(_participant.attributes.stats !== undefined){
              if( state.playersIds.includes(_participant.attributes.stats.playerId)){
                let _playerStat = _playersStats[_participant.attributes.stats.playerId];
      
                _playerStat.totalMatches += 1;
                _playerStat.totalKnocked += _participant.attributes.stats.DBNOs;
                _playerStat.totalAssists += _participant.attributes.stats.assists;
                _playerStat.totalDamageDealt += _participant.attributes.stats.damageDealt;
                _playerStat.totalHeadshotsKills += _participant.attributes.stats.headshotKills;
                _playerStat.totalHeals += _participant.attributes.stats.heals;


                _playerStat.kills = [..._playerStat.kills, _participant.attributes.stats.kills];
                _playerStat.killStreak = [..._playerStat.killStreak, _participant.attributes.stats.killStreaks];
                _playerStat.longestKills = [..._playerStat.longestKills, _participant.attributes.stats.longestKill];
                _playerStat.totalKills += _participant.attributes.stats.kills;

                _playerStat.totalRevives += _participant.attributes.stats.revives;
                _playerStat.totalRideDistance += _participant.attributes.stats.rideDistance;
                _playerStat.totalTeamKills += _participant.attributes.stats.teamKills;
                
                _playerStat.timesSurvived = [..._playerStat.timesSurvived, _participant.attributes.stats.timeSurvived];
                _playerStat.totalTimeSurvived += _participant.attributes.stats.timeSurvived;
                
                _playerStat.totalVehicleDestroyed += _participant.attributes.stats.vehicleDestroys;
                _playerStat.totalWalkedDistance += _participant.attributes.stats.walkDistance;
                
                _playerStat.winPlaces = [..._playerStat.winPlaces, _participant.attributes.stats.winPlace];
                _playerStat.totalWinPlace += _participant.attributes.stats.winPlace;

                if(_participant.attributes.stats.winPlace === 1){
                  _playerStat.totalChickenWon += 1;
                }
                
                _playersStats[_participant.attributes.stats.playerId] = _playerStat;
              }
            }
          }
        }
      })
    })

    setState({...state, playersStats: _playersStats, step: 7});
  }

  // STEP 7: Calculate Players Stats
  function calculatePlayersStats(){

    let _playersStats = state.playersStats;

    Object.keys(state.playersStats).map(( key: string, ) => {
      let _playerStat = _playersStats[key];

      if(_playerStat.totalMatches > 0){
        _playerStat.damageDealtAverage = _playerStat.totalDamageDealt / _playerStat.totalMatches;
        _playerStat.headshotsKillsAverage = _playerStat.totalHeadshotsKills / _playerStat.totalMatches;
        _playerStat.healAverage = _playerStat.totalHeals / _playerStat.totalMatches;

        _playerStat.bestKillStreak = Math.max(..._playerStat.killStreak);
        _playerStat.bestKills = Math.max(..._playerStat.kills);
        _playerStat.bestLongestKill = Math.max(..._playerStat.longestKills);
        _playerStat.bestTimeSurvived = Math.max(..._playerStat.timesSurvived);

        _playerStat.killsAverage = _playerStat.totalKills / _playerStat.totalMatches;
        _playerStat.revivesAverage = _playerStat.totalRevives / _playerStat.totalMatches;
        _playerStat.rideDistancesAverage = _playerStat.totalRideDistance / _playerStat.totalMatches;
        _playerStat.teamKillsAverage = _playerStat.totalTeamKills / _playerStat.totalMatches;
        _playerStat.timeSurvivedAverage = _playerStat.totalTimeSurvived / _playerStat.totalMatches;
        _playerStat.vehicleDestroyedAverage = _playerStat.totalVehicleDestroyed / _playerStat.totalMatches;
        _playerStat.walkedDistancesAverage = _playerStat.totalWalkedDistance / _playerStat.totalMatches;

        _playerStat.kdRate = _playerStat.totalKills / (_playerStat.totalMatches - _playerStat.totalChickenWon);

        _playerStat.winPlaceAverage = _playerStat.totalWinPlace / _playerStat.totalMatches;
        _playerStat.bestWinPlace = Math.min(..._playerStat.winPlaces);

        _playerStat.chickenWonAverage = _playerStat.totalChickenWon / _playerStat.totalMatches;

        _playerStat.playerScore = ((_playerStat.totalChickenWon * 1000) 
                                  +  (_playerStat.totalKills * 100)
                                  + (_playerStat.totalKnocked * 10)
                                  + (_playerStat.totalAssists * 1)
                                  +  (_playerStat.totalDamageDealt)) / _playerStat.totalMatches;
      }
      _playersStats[key] = _playerStat;
    });

    setState({...state, playersStats: _playersStats, step: 8, loading: false});
  }

  // STEP 8: Calculate Players Rank
  function calculatePlayersRank(){
    let _playersRank: PlayerStats[] = [];

    Object.keys(state.playersStats).map(( keyA: string, ) => {
      _playersRank.push(state.playersStats[keyA]);
    });

    _playersRank.sort((a, b) => b.playerScore - a.playerScore);
    setState({...state, RankedPlayers: _playersRank, step: 9});
  }

  // STEP 9: Calculate Medals
  function calculateMedalsOwner(){

    let _playersStats = state.playersStats;

    let _medals = {
      chicken_owner: "-",
      chicken_val: 0,
      killer_owner: "-",
      killer_val: 0,
      sniper_owner: "-",
      sniper_val: 0,
      vampire_owner: "-",
      vampire_val: 0,
      doctor_owner: "-",
      doctor_val: 0,
      fitness_owner: "-",
      fitness_val: 0,
      knocked_owner: "-",
      knocked_val: 0,
      assists_owner: "-",
      assists_val: 0,
      kdrate_owner: "-",
      kdrate_val: 0
    }

    

    Object.keys(state.playersStats).map(( key: string, ) => {
      let _playerStat = _playersStats[key];

      if(_playerStat.totalMatches > 0){

        // CHICKEN MEDAL
        if(_playerStat.totalChickenWon > _medals.chicken_val){
          _medals.chicken_val = _playerStat.totalChickenWon;
          _medals.chicken_owner = _playerStat.name;
        } else if(_playerStat.totalChickenWon === _medals.chicken_val && _medals.chicken_val > 0){
          _medals.chicken_owner += ", " + _playerStat.name;
        }
        // KILLER MEDAL
        if(_playerStat.totalKills > _medals.killer_val){
          _medals.killer_val = _playerStat.totalKills;
          _medals.killer_owner = _playerStat.name;
        } else if(_playerStat.totalKills === _medals.killer_val && _medals.killer_val > 0){
          _medals.killer_owner += ", " + _playerStat.name;
        }
        // SNIPER MEDAL
        if(_playerStat.bestLongestKill > _medals.sniper_val){
          _medals.sniper_val = _playerStat.bestLongestKill;
          _medals.sniper_owner = _playerStat.name;
        } else if(_playerStat.bestLongestKill === _medals.sniper_val && _medals.sniper_val > 0){
          _medals.sniper_owner += ", " + _playerStat.name;
        }
        // VAMPIRE MEDAL
        if(_playerStat.totalDamageDealt > _medals.vampire_val){
          _medals.vampire_val = _playerStat.totalDamageDealt;
          _medals.vampire_owner = _playerStat.name;
        } else if(_playerStat.totalDamageDealt === _medals.vampire_val && _medals.vampire_val > 0){
          _medals.vampire_owner += ", " + _playerStat.name;
        }
        // DOCTOR MEDAL
        if(_playerStat.totalRevives > _medals.doctor_val){
          _medals.doctor_val = _playerStat.totalRevives;
          _medals.doctor_owner = _playerStat.name;
        } else if(_playerStat.totalRevives === _medals.doctor_val && _medals.doctor_val > 0){
          _medals.doctor_owner += ", " + _playerStat.name;
        }
        // FITNESS MEDAL
        let totalDistanceTraveled = (_playerStat.totalWalkedDistance + _playerStat.totalRideDistance);
        if(totalDistanceTraveled > _medals.fitness_val){
          _medals.fitness_val = totalDistanceTraveled;
          _medals.fitness_owner = _playerStat.name;
        } else if(totalDistanceTraveled === _medals.fitness_val && _medals.fitness_val > 0){
          _medals.fitness_owner += ", " + _playerStat.name;
        }
        // KNOCKED MEDAL
        if(_playerStat.totalKnocked > _medals.knocked_val){
          _medals.knocked_val = _playerStat.totalKnocked;
          _medals.knocked_owner = _playerStat.name;
        } else if(_playerStat.totalKnocked === _medals.knocked_val && _medals.knocked_val > 0){
          _medals.knocked_owner += ", " + _playerStat.name;
        }
        // ASSISTS MEDAL
        if(_playerStat.totalAssists > _medals.assists_val){
          _medals.assists_val = _playerStat.totalAssists;
          _medals.assists_owner = _playerStat.name;
        } else if(_playerStat.totalAssists === _medals.assists_val && _medals.assists_val > 0){
          _medals.assists_owner += ", " + _playerStat.name;
        }
        // K/D RATE MEDAL
        if(_playerStat.kdRate > _medals.kdrate_val){
          _medals.kdrate_val = _playerStat.kdRate;
          _medals.kdrate_owner = _playerStat.name;
        } else if(_playerStat.kdRate === _medals.kdrate_val && _medals.kdrate_val > 0){
          _medals.kdrate_owner += ", " + _playerStat.name;
        }
      }
    });

    setState({...state, medals: _medals, step: 45});
    //setState({...state, playersStats: _playersStats, step: 8, loading: false});
  }
  

  
  // -================= EXTRA FUNCTIONS ==============================
  function getTheme(theme: string){
    require('../assets/styles/css/themes/theme_'+theme+'.css');
  }

  function changeTimeFilter(op: string){
    setTimefilter(op);

    let na = op.replace("_", " ");
    na = na.toUpperCase();

    setTimeNamefilter(na);
  }




  

  // VIEW RETURNED
  return (
    <div className="players_stats_container">
      <div className="players_stats_div">
        <div className="header_div">
            <div className="header_div_tiles">
              <div className="header_div_tile_left">
                <span className="header_div_title">{state.teamDetails.name}</span>
              </div>
              <div className="header_div_tile_center">
                <img src={state.teamDetails.logo_url} className="header_div_logo"></img>
              </div>
              <div className="header_div_tile_right">
                <span className="header_div_title">{timeNamefilter} RESULTS</span>            
              </div>
            </div>
          </div>

          <div className="filter_container">
            <select name="filter_time" id="filter_time" className="dropdown" onChange={e=> {changeTimeFilter(e.target.value)} } value={timefilter}>
              <option value="today">Today</option>
              <option value="yesterday">Yesterday</option>
              <option value="this_week">This Week</option>
              <option value="last_week">Last Week</option>
              <option value="this_month">This Month</option>
              <option value="last_month">Last Month</option>
              <option value="this_year">This Year</option>
              <option value="last_year">Last Year</option>
              <option value="lifetime">Lifetime</option>
            </select>
          </div>
          
          <table className='ranking_table'>
            <thead className="ranking_table_thead">
              <tr>
                <th>RANK</th>
                <th>PLAYER</th>
                <th>MATCHES</th>
                <th>KILLS</th>
                <th>KNOCKED</th>
                <th>ASSISTS</th>
                <th>REVIVES</th>
                <th>DAMAGE DEALT 🩸 (ml)</th>
                <th>K/D RATE</th>
                {/* <th>🩸 (ml)</th> */}
                <th>BEST PLACE</th>
                <th>🐔</th>
                <th>TRAVELED (Km)</th>
                <th>TIME (h)</th>
                <th>L-KILL (m)</th>
                <th>SCORE</th>
              </tr>
            </thead>
            <tbody className="ranking_table_tbody">
              { state.RankedPlayers.map((_rankedPlayer, index) => {
                return (
                  <tr key={index}>
                    <td><span className="ranking_table_tbody_td_rank">#{index+1}</span></td>
                    <td className="ranking_table_tbody_td_name">{_rankedPlayer.name}</td>
                    <td>{_rankedPlayer.totalMatches}</td>
                    <td className={_rankedPlayer.totalKills === state.medals.killer_val? "ranking_table_tbody_td_name" : ""}>{_rankedPlayer.totalKills}</td>
                    <td className={_rankedPlayer.totalKnocked === state.medals.knocked_val? "ranking_table_tbody_td_name" : ""}>{_rankedPlayer.totalKnocked}</td>
                    <td className={_rankedPlayer.totalAssists === state.medals.assists_val? "ranking_table_tbody_td_name" : ""}>{_rankedPlayer.totalAssists}</td>
                    <td className={_rankedPlayer.totalRevives === state.medals.doctor_val? "ranking_table_tbody_td_name" : ""}>{_rankedPlayer.totalRevives}</td>
                    <td className={_rankedPlayer.totalDamageDealt === state.medals.vampire_val? "ranking_table_tbody_td_name" : ""}>{formatTnumber(_rankedPlayer.totalDamageDealt)}</td>
                    <td className={_rankedPlayer.kdRate === state.medals.kdrate_val? "ranking_table_tbody_td_name" : ""}>{formatTnumber(_rankedPlayer.kdRate)}</td>
                    <td>#{_rankedPlayer.bestWinPlace}</td>
                    <td className="ranking_table_tbody_td_name">{_rankedPlayer.totalChickenWon}</td>
                    <td  className={((_rankedPlayer.totalWalkedDistance + _rankedPlayer.totalRideDistance)) === state.medals.fitness_val? "ranking_table_tbody_td_name" : ""}>{formatTnumber((_rankedPlayer.totalWalkedDistance + _rankedPlayer.totalRideDistance)/1000)}</td>
                    <td>{formatTnumber((_rankedPlayer.totalTimeSurvived)/(60*60))}</td>
                    <td className={_rankedPlayer.bestLongestKill === state.medals.sniper_val? "ranking_table_tbody_td_name" : ""}>{formatTnumber((_rankedPlayer.bestLongestKill))}</td>
                    <td className="ranking_table_tbody_td_name">{formatTnumber(_rankedPlayer.playerScore)}</td>
                  </tr>
                )
              })}
              
              { state.step !== 45 ?
                <tr>
                  <td colSpan={15} className="spinner-td-container">
                    <LoadingSpinner/>
                  </td>
                </tr>
              : <></>}


            </tbody>
          </table>
        </div>
        
        <h1 className="myteams_title">MEDALS</h1>
        <div className="medal_cards_container">

          <div className="row">

            <div className="col">
              <div className="medal_card_root_clearfix">
                  <div className="medal_card">
                      <div className="medal_card_image_div">
                          <img className="medal_card_image_img" src={medal_chicken}/>
                      </div>
                      <div className="medal_card_title_div">
                          <h3 className="medal_card_title_h2">{state.medals.chicken_val}</h3>
                      </div>
                      <div className="medal_card_title_div">
                          <h3 className="medal_card_title_h3">Most Chicken won</h3>
                      </div>
                      <div className="medal_card_title_div">
                          <h4 className="medal_card_title_h4">{state.medals.chicken_owner}</h4>
                      </div>
                  </div>
              </div>
            </div>

            <div className="col">
                <div className="medal_card_root_clearfix">
                  <div className="medal_card">
                      <div className="medal_card_image_div">
                          <img className="medal_card_image_img" src={medal_killer}/>
                      </div>
                      <div className="medal_card_title_div">
                          <h3 className="medal_card_title_h2">{state.medals.killer_val}</h3>
                      </div>
                      <div className="medal_card_title_div">
                          <h3 className="medal_card_title_h3">Most players killed</h3>
                      </div>
                      <div className="medal_card_title_div">
                          <h4 className="medal_card_title_h4">{state.medals.killer_owner}</h4>
                      </div>
                  </div>
              </div>
            </div>

            

            <div className="col">
                <div className="medal_card_root_clearfix">
                  <div className="medal_card">
                      <div className="medal_card_image_div">
                          <img className="medal_card_image_img" src={medal_sniper}/>
                      </div>
                      <div className="medal_card_title_div">
                          <h3 className="medal_card_title_h2">{formatTnumber(state.medals.sniper_val)} m</h3>
                      </div>
                      <div className="medal_card_title_div">
                          <h3 className="medal_card_title_h3">Longest kill</h3>
                      </div>
                      <div className="medal_card_title_div">
                          <h4 className="medal_card_title_h4">{state.medals.sniper_owner}</h4>
                      </div>
                  </div>
              </div>
            </div>

            
            <div className="col">
                <div className="medal_card_root_clearfix">
                  <div className="medal_card">
                      <div className="medal_card_image_div">
                          <img className="medal_card_image_img" src={medal_vampire}/>
                      </div>
                      <div className="medal_card_title_div">
                          <h3 className="medal_card_title_h2">{formatTnumber(state.medals.vampire_val)} mL</h3>
                      </div>
                      <div className="medal_card_title_div">
                          <h3 className="medal_card_title_h3">Most blood drunk</h3>
                      </div>
                      <div className="medal_card_title_div">
                          <h4 className="medal_card_title_h4">{state.medals.vampire_owner}</h4>
                      </div>
                  </div>
              </div>
            </div>
            
          </div>
          
          <div className="row">
            <div className="col">
            </div>

            <div className="col">
                <div className="medal_card_root_clearfix">
                  <div className="medal_card">
                      <div className="medal_card_image_div">
                          <img className="medal_card_image_img" src={medal_doctor}/>
                      </div>
                      <div className="medal_card_title_div">
                          <h3 className="medal_card_title_h2">{state.medals.doctor_val}</h3>
                      </div>
                      <div className="medal_card_title_div">
                          <h3 className="medal_card_title_h3">Most teammates healed</h3>
                      </div>
                      <div className="medal_card_title_div">
                          <h4 className="medal_card_title_h4">{state.medals.doctor_owner}</h4>
                      </div>
                  </div>
              </div>
            </div>

            <div className="col">
                <div className="medal_card_root_clearfix">
                  <div className="medal_card">
                      <div className="medal_card_image_div">
                          <img className="medal_card_image_img" src={medal_fitness}/>
                      </div>
                      <div className="medal_card_title_div">
                          <h3 className="medal_card_title_h2">{formatTnumber(state.medals.fitness_val/1000)} km</h3>
                      </div>
                      <div className="medal_card_title_div">
                          <h3 className="medal_card_title_h3">Most km traveled</h3>
                      </div>
                      <div className="medal_card_title_div">
                          <h4 className="medal_card_title_h4">{state.medals.fitness_owner}</h4>
                      </div>
                  </div>
              </div>
            </div>
            
            <div className="col">
            </div>
          </div>
          
        </div>

    </div>
  )
}

export default TeamStats;  


