import {FC, useContext} from "react";
import { Link } from "react-router-dom";
import { ReactSession } from 'react-client-session';

// CONTEXT 
import { UserContextType } from "../models/types/contextTypes/userContextType";
import { UserContext } from "../contexts/UserContext";
import { useNavigate } from "react-router-dom";

// STYLE
import '../assets/styles/css/components/navbar.css';

// MODELS
import User from "../models/User";
import INavBarProps from "../models/modalProps/navbarProps";


const Navbar  = ({showModalService: showHelpModal} : INavBarProps) => {

    // NAVIGATOR
    const navigate = useNavigate();
    
    // CONTEXT
    const userContext = useContext(UserContext) as UserContextType;


    // FUNCTIONS CORE ---------------------------------
    function performLogout(){
        // clearing session
        ReactSession.remove("user");
        // clearing context
        userContext.setUser(new User("", ""));
        navigate("/login");
    }



    // VIEW RETURNED
    return (
        <div className="navbar_div">
            {userContext.user.email !== "" ?
                <>
                    <Link to="/myteams"><button className="navbar_button">MY TEAMS</button></Link>
                </>
                :
                <></>
            }
            
          <button  className="navbar_button" onClick={() => {showHelpModal();}}>HELP / INFORMATION</button>
          
          {userContext.user.email !== "" ?
            <>
                <button className="navbar_text">{userContext.user.username}</button>
                <button className="navbar_button" onClick={() => {performLogout();}}>LOGOUT</button>
            </>
            :
            <Link to="/login"><button className="navbar_button">LOGIN</button></Link>
            }
        </div>
    )
}

export default Navbar;


