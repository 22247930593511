export function validateEmail(email: string){
    return (email.includes("@") && email.length > 3 );
}

export function validateUsername(username: string){
    return (username.length > 0);
}

export function validatePassword(password: string){
    return (password.length >= 4);
}

export function validateName(username: string){
    return (username.length > 0);
}

export function validateLogin(email: string) {
    return validateEmail(email);
}

export function validateCreateAccount(email: string, username: string, password: string) {
    return validateEmail(email) && validateUsername(username) && validatePassword(password);
}