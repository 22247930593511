import {FC, useContext, useEffect, useState} from "react";
import axios from "axios";
import { UserContext } from "../contexts/UserContext";


// STYLES 
import '../assets/styles/css/views/myTeams.css';

// MODELS
import ITeamResponse from "../models/interfaces/teamsResponse";

// MODALS
import ModalTeamForm from "../components/modals/ModalTeamForm";
import ModalTeamDelete from "../components/modals/ModalTeamDelete";
import ModalShare from "../components/modals/ModalShare";

// CONTEXT 
import { UserContextType } from "../models/types/contextTypes/userContextType";

// TYPES
import { TTeamDetails } from "../models/types/TTeamDetails";

// COMPONENTS
import LoadingSpinner from "../components/LoadingSpinner";

// CONFIGS
import Configs from "../api/configs/configs";



const MyTeams: FC = ({}) => {

    // CONTEXT
    const userContext = useContext(UserContext) as UserContextType;

    // STATES
    const [myTeams, setMyTeams] = useState<TTeamDetails[]>([]);
    const [displayFormTeamModal, setDisplayFormTeamModal] = useState(false);
    const [displayDeleteTeamModal, setDisplayDeleteTeamModal] = useState(false);
    const [displayShareModal, setDisplayShareModal] = useState(false);
    const [workingTeam, setWorkingTeam] = useState<TTeamDetails>({teamID: "", theme: "", logo_url: "", members: "", name: ""});
    const [loading, setLoading] = useState<boolean>(true);


    
    // ON START 
    useEffect(() => {
        getMyTeams();
    }, [])



    // FUNCTIONS CORE --------------------------------
    function getMyTeams(){
        setLoading(true);
        let config = {
            headers: {
              'Accept': "application/json",
            },
            withCredentials: false
          }

        let body = {
            email: userContext.user.email
        }

        axios.post<ITeamResponse>(Configs.GTEAMSTATS_BASEURL + 'TeamsGet.php', body, config)
            .then(res => {
                setLoading(false);
                
                if(res.data.code === 1){
                    let _myTeams: TTeamDetails[] = [];

                    //GOTTEN
                    res.data.data.map((_team, index) => {
                        let _myTeam = {
                            teamID: _team.team_id,
                            theme: _team.team_theme,
                            logo_url: _team.team_logourl,
                            members: _team.team_players,
                            name: _team.team_name
                        };

                        _myTeams.push(_myTeam);
                    });

                    setMyTeams(_myTeams);
                    
                } 
            })
            .catch(err => {
                setLoading(false);
                console.log("ERROR");
                console.log(err);
                //setMessage("Internal error");
            });      
    }

    
    function hideFormTeamModal(){
        setDisplayFormTeamModal(false);
    }
    function showFormTeamModal(creating: boolean){
        if(creating){
            setWorkingTeam({teamID: "", theme: "", logo_url: "", members: "", name: ""});
        }
        setDisplayFormTeamModal(true);
    }
    function hideDeleteTeamModal(){
        setDisplayDeleteTeamModal(false);
    }
    function showDeleteTeamModal(){
        setDisplayDeleteTeamModal(true);
    }
    function hideShareModal(){
        setDisplayShareModal(false);
    }
    function showShareModal(){
        setDisplayShareModal(true);
    }


    function performEditTeam(index: number){
        setWorkingTeam(myTeams[index]);
        showFormTeamModal(false);
    }
    function performDeleteTeam(index: number){
        setWorkingTeam(myTeams[index]);
        showDeleteTeamModal();
    }
    function performShare(index: number){
        setWorkingTeam(myTeams[index]);
        showShareModal();
    }






    return (
        <div className="myteams_container">
            <h1 className="myteams_title">MY TEAMS</h1>
            <div className="div_myteams_action_buttons">
                <button onClick={() => {showFormTeamModal(true);}} className="btn btn-primary">New Team</button>
            </div>
            
            <div className="teams_cards_container">
                { myTeams.map((team, index) => {
                    return (
                        <div className="team_card_root_clearfix" key={team.teamID}>
                            <div className="team_card">
                                <div className="team_card_image_div">
                                    <img className="team_card_image_img" src={team.logo_url}/>
                                </div>
                                <div className="team_card_title_div">
                                    <h3 className="team_card_title_h3">{team.name}</h3>
                                </div>
                                <div className="team_card_buttons_div">
                                    <button className="team_card_buttons_btn team_card_buttons_btn_primary" onClick={() => { window.open('/team/'+team.teamID);}} >STATS</button>
                                    <button className="team_card_buttons_btn team_card_buttons_btn_green" onClick={() => {performShare(index);}}>SHARE</button>
                                    <button className="team_card_buttons_btn team_card_buttons_btn_secondary" onClick={() => {performEditTeam(index)}}>EDIT</button>
                                    <button className="team_card_buttons_btn team_card_buttons_btn_danger" onClick={() => {performDeleteTeam(index)}}>DEL</button>
                                </div>
                            </div>
                        </div>
                    )
                })}
                
            </div>
            
            { displayFormTeamModal ?
            <ModalTeamForm hideModalService={hideFormTeamModal} team={workingTeam} getMyTeamsService={getMyTeams} />
            : <></>}
            { displayDeleteTeamModal ?
            <ModalTeamDelete hideModalService={hideDeleteTeamModal} team={workingTeam} getMyTeamsService={getMyTeams} />
            : <></>}
            { displayShareModal ?
            <ModalShare hideModalService={hideShareModal} team={workingTeam} />
            : <></>}


            { loading  ?
                <div className="div_spinner_container">
                  <LoadingSpinner/>
                </div>
            : <></>}
        </div>
        
    )
}

export default MyTeams;