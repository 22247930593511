import React, {FC} from "react";

import '../assets/styles/css/components/footer.css';

const Footer: FC = ({}) => {

    return (
        <div className="footer">
            <span>Powered by <a href="https://elesteam.com" target="_blank">Elesteam</a> • Copyright © Elezero</span>
        </div>
    )
}

export default Footer;