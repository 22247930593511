// STYLES
import '../../assets/styles/css/components/modal/modal.css';
import '../../assets/styles/css/components/modal/modalFormTeam.css';

// MODELS
import IModalShareProps from '../../models/modalProps/modalShareProps';



const ModalShare = ({hideModalService: hideModal, team: passedTeam} : IModalShareProps) => {

    

    return (
        <div id="myModal" className="modal">
            <div className="modal-content">
                <span className="close" onClick={() => { hideModal();}}>&times;</span>
                <h1 className="modal-content-subtitle"> Share team link</h1>
                <input readOnly={true} className="form-input" type="text" value={window.location.host+'/team/'+passedTeam.teamID}/>
                <button className="modal-button-secondary" onClick={() => {hideModal();}} >CLOSE</button>

            </div>
        </div>
    )
}

export default ModalShare;