 export function isToday(someDateRaw: Date){
    let someDate = new Date(someDateRaw);
    let today = new Date()
    today.setHours(0,0,0,0);

    return someDate >= today;
  }

 export function isFromYesterday(someDateRaw: Date){
    let someDate = new Date(someDateRaw);

    let yesterday = new Date(new Date().getTime() - 24*60*60*1000);
    yesterday.setHours(0,0,0,0);
    
    let today = new Date()
    today.setHours(0,0,0,0);

    return (someDate >= yesterday && someDate < today);
  }

 export function isThisWeek(someDateRaw: Date){
    let someDate = new Date(someDateRaw);

    const todayObj = new Date();
    const todayDate = todayObj.getDate();
    const todayDay = todayObj.getDay();
  
    // get first date of week
    let firstDayOfWeek = new Date(todayObj.setDate((todayDate - todayDay)+1));
    firstDayOfWeek.setHours(0,0,0,0);

    // get last date of week
    const lastDayOfWeek = new Date(firstDayOfWeek);
    lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 7);

    return someDate >= firstDayOfWeek && someDate < lastDayOfWeek;
  }

 export function isLastWeek(someDateRaw: Date){
    let someDate = new Date(someDateRaw);

    const todayObj = new Date();
    const todayDate = todayObj.getDate();
    const todayDay = todayObj.getDay();
  
    // get first date of week
    let firstDayOfWeek = new Date(todayObj.setDate((todayDate - todayDay)+(1 - 7)));
    firstDayOfWeek.setHours(0,0,0,0);
    
    // get last date of week
    const lastDayOfWeek = new Date(firstDayOfWeek);
    lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 7);
  
    return (someDate >= firstDayOfWeek && someDate < lastDayOfWeek);
  }

 export function isThisMonth(someDateRaw: Date){
    let someDate = new Date(someDateRaw);

    let todayObj = new Date();
    todayObj.setHours(0,0,0,0);

    return (todayObj.getMonth() == someDate.getMonth() && todayObj.getFullYear() === someDate.getFullYear());
  }

  export function isLastMonth(someDateRaw: Date){
    let someDate = new Date(someDateRaw);

    let todayObj = new Date();
    todayObj.setHours(0,0,0,0);
    todayObj.setMonth((todayObj.getMonth() -1));

    return (todayObj.getMonth() == someDate.getMonth() && todayObj.getFullYear() === someDate.getFullYear());
  }

  export function isThisYear(someDateRaw: Date){
    let someDate = new Date(someDateRaw);

    let todayObj = new Date();
    todayObj.setHours(0,0,0,0);

    return todayObj.getFullYear() === someDate.getFullYear();
  }

  export function isLastYear(someDateRaw: Date){
    let someDate = new Date(someDateRaw);

    let todayObj = new Date();
    todayObj.setHours(0,0,0,0);
    todayObj.setFullYear(todayObj.getFullYear() -1);

    return todayObj.getFullYear() === someDate.getFullYear();
  }