import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { ReactSession } from 'react-client-session';

// VIEWS
import TeamStats from './app/views/TeamStats';
import Login from './app/views/Login';
import MyTeams from './app/views/MyTeams';

// COMPONENTS
import Navbar from './app/components/Navbar';
import Footer from './app/components/Footer';

// HELPERS
import { getRandomInt } from './app/helpers/NumberHelper';
import { UserContext } from './app/contexts/UserContext';

// MODELS
import User from './app/models/User';

// MODALS
import ModalHelp from './app/components/modals/ModalHelp';

// STYLE 
import './App.css';
import './app/assets/styles/css/themes/theme_orange.css'; // DEV-REMOVE 

// IMAGES
import pubg_walls_0 from './app/assets/images/walls/pubg_wall_0.jpg';
import pubg_walls_1 from './app/assets/images/walls/pubg_wall_1.jpg';
import pubg_walls_2 from './app/assets/images/walls/pubg_wall_2.jpg';
import pubg_walls_3 from './app/assets/images/walls/pubg_wall_3.jpg';
import pubg_walls_4 from './app/assets/images/walls/pubg_wall_4.jpg';
import pubg_walls_5 from './app/assets/images/walls/pubg_wall_5.jpg';
import pubg_walls_6 from './app/assets/images/walls/pubg_wall_6.jpg';
import pubg_walls_7 from './app/assets/images/walls/pubg_wall_7.jpg';
import pubg_walls_8 from './app/assets/images/walls/pubg_wall_8.jpg';
import pubg_walls_9 from './app/assets/images/walls/pubg_wall_9.jpg';
import pubg_walls_10 from './app/assets/images/walls/pubg_wall_10.jpg';
import pubg_walls_11 from './app/assets/images/walls/pubg_wall_11.jpg';
import pubg_walls_12 from './app/assets/images/walls/pubg_wall_12.jpg';
import pubg_walls_13 from './app/assets/images/walls/pubg_wall_13.jpg';
import pubg_walls_14 from './app/assets/images/walls/pubg_wall_14.jpg';
import pubg_walls_15 from './app/assets/images/walls/pubg_wall_15.jpg';
import pubg_walls_16 from './app/assets/images/walls/pubg_wall_16.jpg';
import pubg_walls_17 from './app/assets/images/walls/pubg_wall_17.jpg';
import pubg_walls_18 from './app/assets/images/walls/pubg_wall_18.jpg';
import pubg_walls_19 from './app/assets/images/walls/pubg_wall_19.jpg';
import pubg_walls_20 from './app/assets/images/walls/pubg_wall_20.jpg';
import pubg_walls_21 from './app/assets/images/walls/pubg_wall_21.jpg';
import pubg_walls_22 from './app/assets/images/walls/pubg_wall_22.jpg';
import pubg_walls_23 from './app/assets/images/walls/pubg_wall_23.jpg';
import pubg_walls_24 from './app/assets/images/walls/pubg_wall_24.jpg';
import pubg_walls_25 from './app/assets/images/walls/pubg_wall_25.jpg';
import pubg_walls_26 from './app/assets/images/walls/pubg_wall_26.jpg';
import pubg_walls_27 from './app/assets/images/walls/pubg_wall_27.jpg';
import pubg_walls_28 from './app/assets/images/walls/pubg_wall_28.jpg';
import pubg_walls_29 from './app/assets/images/walls/pubg_wall_29.jpg';
import pubg_walls_30 from './app/assets/images/walls/pubg_wall_30.jpg';




function App() {
  // SESSION 
  ReactSession.setStoreType("localStorage");

  // USER SESSION MANAGEMET
  const [user, setUser] = useState<User>(new User("", ""));
  const providerUser = useMemo(() => ({ user, setUser }), [user, setUser]);

  // STATES
  const [wallpaper, setWallpaper] = useState<string>("");
  const [displayHelpModal, setDisplayHelpModal] = useState(false);

  
  // ON PAGE START
  useEffect(() => {
    getWallpaper();
    checkSession();
  }, []);


  // FUNCTIONS CORE --------------------------------------------
  function getWallpaper(){
    let ran = getRandomInt(0, 30);
  
    let wallArray = [
      pubg_walls_0,
      pubg_walls_1,
      pubg_walls_2,
      pubg_walls_3,
      pubg_walls_4,
      pubg_walls_5,
      pubg_walls_6,
      pubg_walls_7,
      pubg_walls_8,
      pubg_walls_9,
      pubg_walls_10,
      pubg_walls_11,
      pubg_walls_12,
      pubg_walls_13,
      pubg_walls_14,
      pubg_walls_15,
      pubg_walls_16,
      pubg_walls_17,
      pubg_walls_18,
      pubg_walls_19,
      pubg_walls_20,
      pubg_walls_21,
      pubg_walls_22,
      pubg_walls_23,
      pubg_walls_24,
      pubg_walls_25,
      pubg_walls_26,
      pubg_walls_27,
      pubg_walls_28,
      pubg_walls_29,
      pubg_walls_30,
    ]
  
    setWallpaper(wallArray[ran]);
  }

  function checkSession(){
    // checking stored session
    const sessionUser = ReactSession.get("user") as User;

    if(sessionUser !== undefined){
      if(sessionUser.email !== ""){
        providerUser.setUser(sessionUser);
      }
    }
  }

  
  function showHelpModal(){
    setDisplayHelpModal(true);
  }

  function hideHelpModal(){
    setDisplayHelpModal(false);
  }


  
  return (
    <BrowserRouter>
      <UserContext.Provider value={providerUser}>
        <div className='App' >
          
          <div className="background_div" style={{backgroundImage: `url(${wallpaper})`}}>
            <div className="container">
           
                <Navbar showModalService={showHelpModal} />

                <div className='AppContent'>
                      <Routes>
                          <Route path='/' element={providerUser.user.email === "" ? <Navigate to="/login" /> : <Navigate to="/myteams" /> } />
                          <Route path='/login' element={ providerUser.user.email === "" ? <Login/> : <Navigate to="/myteams" /> } />
                          <Route path='/team/:team_id' element={<TeamStats/>} />                
                          <Route path='/myteams' element={providerUser.user.email === "" ? <Navigate to="/login" /> : <MyTeams /> } />       
                      </Routes>     
                </div>
              
            </div>
          </div>
        </div>
      
        <Footer/>
        

        { displayHelpModal ?
          <ModalHelp hideModalService={hideHelpModal}  />
        : <></>}

      </UserContext.Provider>    
    </BrowserRouter>
  );
}

export default App;
